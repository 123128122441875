import { CarouselItem } from '../Interface/CarouselComponentProps';

import apartment3Hallway from '../images/photo/apartment3/hallway.jpg'
import apartment3Bedroom1 from '../images/photo/apartment3/bedroom1.jpg'
import apartment3Bedroom2 from '../images/photo/apartment3/bedroom2.jpg'
import apartment3Bathroom from '../images/photo/apartment3/bathroom.jpg'
import apartment3Kitchen from '../images/photo/apartment3/kitchen.jpg'
import apartment3Balcony from '../images/photo/apartment3/balcony.jpg'


export const apartment3CarouselItems: CarouselItem[] = [
    {
        image: apartment3Hallway,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment3Bedroom1,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment3Bedroom2,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment3Bathroom,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment3Kitchen,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment3Balcony,
        caption: {
            title: "",
            description: ""
        }
    }


];