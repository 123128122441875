import { CarouselItem } from '../Interface/CarouselComponentProps';

import apartment1Hallway1 from '../images/photo/apartment1/hallway1.jpg'
import apartment1Hallway2 from '../images/photo/apartment1/hallway2.jpg'
import apartment1Bedroom1 from '../images/photo/apartment1/bedroom1.jpg'
import apartment1Passage from '../images/photo/apartment1/passage.jpg'
import apartment1Kitchen from '../images/photo/apartment1/kitchen.jpg'
import apartment1Bathroom1 from '../images/photo/apartment1/bathroom1.jpg'
import apartment1Bathroom2 from '../images/photo/apartment1/bathroom2.jpg'
import apartment1Bedroom2 from '../images/photo/apartment1/bedroom2.jpg'


export const apartment1CarouselItems: CarouselItem[] = [
    {
        image: apartment1Hallway1,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment1Hallway2,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment1Bedroom1,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment1Passage,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment1Kitchen,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment1Bathroom2,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment1Bathroom1,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment1Bedroom2,
        caption: {
            title: "",
            description: ""
    }
}

];