import LogoVillaComponent from "./LogoComponent"
import BookingLogo from '../images/logo/booking-logo.svg';
import FacebookLogo from '../images/logo/facebook-logo3.png';
import InstagramLogo from '../images/logo/instagram-logo2.png';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Style/FooterStyle.css";


const FooterComponent = () => {
    const handleBookingClick = () => {
        window.open('https://www.booking.com/hotel/it/villa-anastasia-porto-cesareo.it.html', '_blank');
    };

    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/villaanastasia2021?igsh=bGxqZ3F1c2xkNWkz&utm_source=qr', '_blank');
    };

    const handleFacebookClick = () => {
        window.open('https://www.facebook.com/villa.anastasia.58', '_blank');
    };
    return (
        <>
            {/* Grid Container */}
            <footer id="footer-basic-style">
                {/* Grid row */}
                <div className="pt-4 pb-2 d-flex justify-content-center row">
                    {/* Grid Column */}
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3 col-xxl-2 align-self-end">
                            <div className="d-flex flex-column align-items-center ">
                                <div id="logo-container">
                                    <LogoVillaComponent width='200px' />
                                </div>
                                <div className="mt-4">
                                    <div className="row">
                                        <div className="mb-4 fs-5 fw-bold">Contact</div>
                                        <div className="col">
                                            <div className="">
                                                Via circonvallazione 133,<br />
                                                Porto Cesareo, Lecce<br />
                                                73010<br />
                                                <strong>+39 3927546880<br /></strong>
                                                villaanastasia2020@gmail.com<br />
                                            </div>
                                            <div className="d-flex justify-content-around mt-4">
                                                <a href="URL" onClick={handleBookingClick}>
                                                    <img src={BookingLogo} alt="Logo Booking" width={'32px'} />
                                                </a>
                                                <a href="URL" onClick={handleInstagramClick}>
                                                    <img src={InstagramLogo} alt="Logo Instagram" width={'32x'} />
                                                </a>
                                                <a href="URL" onClick={handleFacebookClick}>
                                                    <img src={FacebookLogo} alt="Logo Facebook" width={'31px'} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="list-unstyled d-flex flex-row justify-content-center">
                                    <li>
                                        <a className="text-white px-2" href="#!">
                                            <i className="fab fa-facebook-square"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-white px-2" href="#!">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-white ps-2" href="#!">
                                            <i className="fab fa-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Grid Column */}
                        <div className="col xxl-1 col-xl-2 col-lg-3 col-md-3 d-flex justify-content-center align-self-center mb-4">
                            <div className="mt-4">
                                <div className="row">
                                    <div className="mb-4 mt-4 fs-5 fw-bold">Orari</div>
                                    <div className="col">
                                        <div className="">
                                            CHECK-IN:<br/>
                                            Dalle 14:00 alle 16:30<br />
                                            <br/>
                                            CHECK-OUT<br/>
                                            Dalle ore 8:00 alle ore 10:00<br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Grid column */}
                        <div className="col-12 col-lg-5 col-xl-6 col-xxl-6 mt-4">
                            <iframe
                                title="Google Maps"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3043.974076804444!2d17.874251076396824!3d40.27632606417219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1346991c72c943d5%3A0xcaf8d74dc1e9ca31!2sVilla%20Anastasia!5e0!3m2!1sit!2sit!4v1707122069471!5m2!1sit!2sit"
                                width="100%"
                                height="450"
                                style={{ border: '0' }}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </footer >
        </>
    )
}
export default FooterComponent