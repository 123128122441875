import { CarouselItem } from '../Interface/CarouselComponentProps';

import apartment4Bedroom1 from '../images/photo/apartment4/bedroom1.jpg'
import apartment4Bedroom2 from '../images/photo/apartment4/bedroom2.jpg'
import apartment4Bathroom from '../images/photo/apartment4/bathroom.jpg'
import apartment4Kitchen1 from '../images/photo/apartment4/kitchen1.jpg'
import apartment4Kitchen2 from '../images/photo/apartment4/kitchen2.jpg'
import apartment4CoveredTerrace from '../images/photo/apartment4/covered-terrace.jpg'


export const apartment4CarouselItems: CarouselItem[] = [
    {
        image: apartment4Bedroom1,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment4Bedroom2,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment4Bathroom,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment4Kitchen1,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment4Kitchen2,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment4CoveredTerrace,
        caption: {
            title: "",
            description: ""
        }
    }

];