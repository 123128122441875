import { CarouselItem } from '../Interface/CarouselComponentProps';

import apartment2Hallway from '../images/photo/apartment2/hallway.jpg'
import apartment2Bedroom from '../images/photo/apartment2/bedroom.jpg'
import apartment2Bathroom from '../images/photo/apartment2/bathroom.jpg'
import apartment2Kitchen1 from '../images/photo/apartment2/kitchen1.jpg'
import apartment2Kitchen2 from '../images/photo/apartment2/kitchen2.jpg'
import apartment2Balcony from '../images/photo/apartment2/balcony.jpg'


export const apartment2CarouselItems: CarouselItem[] = [
    {
        image: apartment2Hallway,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment2Bedroom,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment2Bathroom,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment2Kitchen1,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment2Kitchen2,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: apartment2Balcony,
        caption: {
            title: "",
            description: ""
        }
    }


];