import { CarouselItem } from '../Interface/CarouselComponentProps';

import atticBedroom1 from '../images/photo/attic/bedroom1.jpg'
import atticBedroom2 from '../images/photo/attic/bedroom2.jpg'
import atticBedroom3 from '../images/photo/attic/bedroom3.jpg'
import atticBathroom from '../images/photo/attic/bathroom.jpg'
import atticKitchen from '../images/photo/attic/kitchen.jpg'
import atticPatio from '../images/photo/attic/patio.jpg'
import atticTerrace from '../images/photo/attic/terrace.jpg'
import atticTerraceRelaxArea from '../images/photo/attic/terrace-relax-area.jpg'


export const atticCarouselItems: CarouselItem[] = [
    {
        image: atticBedroom1,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: atticBedroom2,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: atticBedroom3,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: atticBathroom,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: atticKitchen,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: atticPatio,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: atticTerrace,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: atticTerraceRelaxArea,
        caption: {
            title: "",
            description: ""
        }
    }


];