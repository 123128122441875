
import LogoComponent from './LogoComponent';
import "../Style/NavbarStyle.css";
import { useEffect, useState } from 'react';

/**
 * Navbar Component
 * 
 * @returns {JSX.Element} JSX component
 */

const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth', // Aggiunge l'effetto di scorrimento fluido
  });
}
const returnInHomepage = () => {
  window.location.href = '/homepage';
  ;
}

const NavbarComponent = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
  }, []);
  const isSmallScreen = windowWidth < 420;


  return (
    <>
      <div className="row p-4">
        {/* 
          <div className="col d-flex justify-content-between align-items-center bg-prymary">
            <select className="form-select form-select-sm custom-select" aria-label="Small select example"
              style={{ width : "100px" }} // Imposta la grandezza desiderata
            >
              <option selected
                style={{ fontSize: '18px', marginRight: '20px' }} // Imposta la grandezza desiderata
              >
                ITA/ENG
              </option>
              <option value="1">Ita</option>
              <option value="2">Eng</option>
            </select>
          </div>
          */}
        <div className="col-1 mx-auto d-flex justify-content-center align-items-center">
          <i
            onClick={() => { returnInHomepage() }}
            className="bi bi-house-heart-fill"
            style={{ fontSize: '24px', marginLeft: '20px' }}
            role='button'
          ></i>
        </div>
        <div className={`col-10 d-flex align-items-center justify-content-center ${isSmallScreen ? 'small-screen' : ''}`}>
          <LogoComponent homePageUrl="/homepage" width={isSmallScreen ? '230' : '300'} />
        </div>
        <div className="col-1 mx-auto d-flex justify-content-center align-items-center" >
          <i
            onClick={() => { scrollToBottom() }}
            className="bi bi-info-circle"
            style={{ fontSize: '24px', marginRight: '20px' }}
            role='button'
          ></i>
        </div>
      </div>


    </>
  )
}

export default NavbarComponent;