import './App.css';

// DOCUMENTATION https://react-bootstrap.netlify.app/docs/getting-started/introduction/#importing-components
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import NavbarComponent from './Component/NavbarComponent';
import FooterComponent from './Component/FooterComponent';
import ApartmentServicesAndDescriptionComponent from './Component/ApartmentServicesAndDescriptionComponent';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Homepage from './page/Homepage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBmO3oeLQLyHsQhssj8K5cvt3K9i-kHGK8",
  authDomain: "villa-anastasia-c8cd7.firebaseapp.com",
  projectId: "villa-anastasia-c8cd7",
  storageBucket: "villa-anastasia-c8cd7.appspot.com",
  messagingSenderId: "430871919206",
  appId: "1:430871919206:web:b3d6594d93f4f589848ba7",
  measurementId: "G-V70Z5C3E2D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {

  return (
    <>
      <NavbarComponent />
      <Router>
        <Routes>
          <Route path="" element={<Navigate to="/homepage" />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/apartment/:id" element={<ApartmentServicesAndDescriptionComponent />} />
        </Routes>
      </Router>
      <FooterComponent />
    </>
  );
}

export default App;
