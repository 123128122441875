import '../Style/ApartmentsStyle.css'
import { useNavigate } from 'react-router-dom';
import { apartmentsData } from '../data/ApartmentsData';
import React from 'react';





const ApartmentsComponent: React.FC = () => {

    const [visible, setVisible] = React.useState(false);
    const [apartmentIdScopriDaMostrare, setapartmentIdScopriDaMostrare] = React.useState(0);

    function mouseIsOver(number: number) {
        setapartmentIdScopriDaMostrare(number);
        setVisible(true);
    }

    function mouseIsOut(number: number) {
        setapartmentIdScopriDaMostrare(number);
        setVisible(false);
    }

    const navigate = useNavigate();

    return (
        <>
            <div className="container show-all-cards">
                <div className="row ">
                    <div className="col text-center mb-5">
                        <h2 className="display-2">Scopri i nostri appartamenti</h2>
                    </div>
                </div>
                <div className="row d-flex justify-content-around align-items-center">
                    {apartmentsData.map((apartment, index) => (
                        <div key={index} className="col-sm-12 col-lg-6 col-xxl-4 mb-4"
                            onClick={() => navigate(`/apartment/${apartment.id}`)}
                            onMouseOver={() => mouseIsOver(index)}
                            onMouseOut={() => mouseIsOut(index)}>
                            <div className="card text-dark card-has-bg click-col" style={{ backgroundImage: `url('${apartment.imagesUrl[0]?.url}')` }}>
                                <div className="card-img-overlay d-flex flex-column">
                                    <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <h3 className=" mb-4 text-center">{apartment.title}</h3>
                                        {visible && apartmentIdScopriDaMostrare === index && (
                                            <>
                                                <h3 className=" mb-4 text-center">{apartment.subtitle}</h3>
                                                <div className='bg-black p-1 px-3'>
                                                    <h6 className="display-6 text-white discover-button" >
                                                        SCOPRI
                                                    </h6>
                                                </div>
                                            </>

                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ApartmentsComponent;
