import { ReactElement } from 'react';
import ApartmentsComponent from '../Component/ApartmentsComponent';
import CarouselComponent from '../Component/CarouselComponent';
import { villaAnastasiaCarouselItems } from '../data/VillaAnastasiaData';

const Homepage = (): ReactElement => {
    return (
        <>
            <CarouselComponent carouselItems={villaAnastasiaCarouselItems} />
            <ApartmentsComponent />
        </>
    )
}
export default Homepage;