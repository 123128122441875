import { CarouselItem } from '../Interface/CarouselComponentProps';
import streeview from '../images/photo/external-villa/street-view.jpg'
import viewdrone3 from '../images/photo/external-villa/view-drone3.jpg'
import viewdrone4 from '../images/photo/external-villa/view-drone4.jpg'
import viewdrone5 from '../images/photo/external-villa/view-drone5.jpg'

import internalParking1 from '../images/photo/external-villa/internal-parking1.jpg'
import internalParking2 from '../images/photo/external-villa/internal-parking2.jpg'
import hallway1 from '../images/photo/external-villa/hallway1.jpg'
import hallway2 from '../images/photo/external-villa/hallway2.jpg'
import hallway3 from '../images/photo/external-villa/hallway3.jpg'

export const villaAnastasiaCarouselItems: CarouselItem[] = [
    {
        image: streeview,
        caption: {
            title: "Villa Anastasia",
            description: ""
        }
    },
    {
        image: viewdrone3,
        caption: {
            title: "La struttura è situata a 150m dal mare (3 min a piedi).",
            description: ""
        }
    },
    {
        image: viewdrone4,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: viewdrone5,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: internalParking1,
        caption: {
            title: "Parcheggio privato interno con videosorveglianza e ricarica per auto elettriche",
            description: ""
        }
    },
    {
        image: internalParking2,
        caption: {
            title: "Parcheggio privato interno con videosorveglianza e ricarica per auto elettriche",
            description: ""
        }
    },
    {
        image: hallway1,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: hallway2,
        caption: {
            title: "",
            description: ""
        }
    },
    {
        image: hallway3,
        caption: {
            title: "",
            description: ""
        }
    },

];