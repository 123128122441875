import {
    faDog, faWind, faShower, faBroom, faRestroom, faFireBurner, faSink, faKitchenSet,
    faWifi, faTree, faParking, faUmbrellaBeach, faTv, faHandsBubbles, faBicycle
} from "@fortawesome/free-solid-svg-icons";
import apartment1Hallway1 from '../images/photo/apartment1/hallway1.jpg'
import apartment2Hallway from '../images/photo/apartment2/hallway.jpg'
import apartment3Kitchen from '../images/photo/apartment3/kitchen.jpg'
import apartment4Kitchen1 from '../images/photo/apartment4/kitchen1.jpg'
import atticTerrace from '../images/photo/attic/terrace.jpg'
import { Apartment } from "../Interface/Apartment";


export const apartmentsData: Apartment[] = [
    {
        id: 1,
        title: "Appartamento 1",
        subtitle: "Il tuo nido accogliente",
        imagesUrl: [
            {
                id: 1,
                url: apartment1Hallway1
            }

        ],
        description: "L'Appartamento 1 “ Villa Anastasia “ è il più grande, con una capacità di ospitare fino a 6 persone. Situato al primo piano, composto da ingresso, due camere matrimoniali più letto singolo, due ampi bagni con doccia idromassaggio, cucina spaziosa con tavolo da pranzo, terrazzino con vista bacino. Tutti gli appartamenti sono climatizzati e dotati di TV. L'Appartamento è la soluzione ideale per famiglie, gruppi di amici, coppie che desiderano un soggiorno confortevole e spazioso. Posto auto videosorvegliato.",
        services: [
            { icon: faDog, description: "Animali ammessi" },
            { icon: faWind, description: "Aria condizionata, riscaldamento" },
            { icon: faShower, description: "Doccia esterna" },
            { icon: faBroom, description: "Attrezzi pulizia" },
            { icon: faHandsBubbles, description: "Lavanderia" },
            { icon: faRestroom, description: "Bagno, lavatrice, ferro da stiro" },
            { icon: faFireBurner, description: "Barbecue" },
            { icon: faSink, description: "Docce idromassaggio" },
            { icon: faKitchenSet, description: "Cucina, frigo, forno, stoviglie" },
            { icon: faWifi, description: "Free WiFi" },
            { icon: faTree, description: "Giardino" },
            { icon: faParking, description: "Posto auto" },
            { icon: faUmbrellaBeach, description: "Spiaggia raggiungibile a piedi" },
            { icon: faTv, description: "Smart TV" },
            { icon: faBicycle, description: "Biciclette disponibili" }

        ]
    },
    {
        id: 2,
        title: "Appartamento 2",
        subtitle: "Il tuo rifugio",
        imagesUrl: [
            {
                id: 1,
                url: apartment2Hallway
            },
        ],
        description: "L'Appartamento 2 può ospitare fino a 5 persone. Situato al primo piano, è composto da una camera matrimoniale più un letto singolo, bagno con doccia idromassaggio, ampio soggiorno con divano letto e cucina, tv, climatizzatore indipendente in ogni ambiente. Terrazzino con vista bacino. Posto auto videosorvegliato.",
        services: [
            { icon: faDog, description: "Animali ammessi" },
            { icon: faWind, description: "Aria condizionata, riscaldamento" },
            { icon: faShower, description: "Doccia esterna" },
            { icon: faBroom, description: "Attrezzi pulizia" },
            { icon: faHandsBubbles, description: "Lavanderia" },
            { icon: faRestroom, description: "Bagno, ferro da stiro" },
            { icon: faFireBurner, description: "Barbecue" },
            { icon: faSink, description: "Docce idromassaggio" },
            { icon: faKitchenSet, description: "Cucina, frigo, forno, stoviglie" },
            { icon: faWifi, description: "Free WiFi" },
            { icon: faTree, description: "Giardino" },
            { icon: faParking, description: "Posto auto" },
            { icon: faUmbrellaBeach, description: "Spiaggia raggiungibile a piedi" },
            { icon: faTv, description: "Smart TV" },
            { icon: faBicycle, description: "Biciclette disponibili" }

        ]
    },
    {
        id: 3,
        title: "Appartamento 3",
        subtitle: "Oasi di relax",
        imagesUrl: [
            {
                id: 1,
                url: apartment3Kitchen
            },
        ],
        description: "Può ospitare fino a 4 persone. Situato al primo piano, è composto da una camera matrimoniale più un letto singolo, zona giorno con divano letto e cucina. Bagno con doccia idromassaggio per un tocco di benessere. Tutti gli appartamenti sono climatizzati e dotati di TV. Terrazzino panoramico con vista bacino. Posto auto videosorvegliato.",
        services: [
            { icon: faDog, description: "Animali ammessi" },
            { icon: faWind, description: "Aria condizionata, riscaldamento" },
            { icon: faShower, description: "Doccia esterna" },
            { icon: faBroom, description: "Attrezzi pulizia" },
            { icon: faHandsBubbles, description: "Lavanderia" },
            { icon: faRestroom, description: "Bagno, ferro da stiro" },
            { icon: faFireBurner, description: "Barbecue" },
            { icon: faSink, description: "Docce idromassaggio" },
            { icon: faKitchenSet, description: "Cucina, frigo, forno, stoviglie" },
            { icon: faWifi, description: "Free WiFi" },
            { icon: faTree, description: "Giardino" },
            { icon: faParking, description: "Posto auto" },
            { icon: faUmbrellaBeach, description: "Spiaggia raggiungibile a piedi" },
            { icon: faTv, description: "Smart TV" },
            { icon: faBicycle, description: "Biciclette disponibili" }

        ]
    },
    {
        id: 4,
        title: "Appartamento 4",
        subtitle: "Il tuo angolo di paradiso",
        imagesUrl: [
            {
                id: 1,
                url: apartment4Kitchen1
            },
        ],
        description: "Può ospitare fino a 5 persone. Situato al piano terra, è composto da due camere matrimoniali di cui una con letto singolo. Ampia cucina, bagno con doccia idromassaggio per un tocco di benessere. Giardino privato dove godersi  il sole e la tranquillità, posto auto videosorvegliato.",
        services: [
            { icon: faDog, description: "Animali ammessi" },
            { icon: faWind, description: "Aria condizionata, riscaldamento" },
            { icon: faShower, description: "Doccia esterna" },
            { icon: faBroom, description: "Attrezzi pulizia" },
            { icon: faHandsBubbles, description: "Lavanderia" },
            { icon: faRestroom, description: "Bagno, lavatrice, ferro da stiro" },
            { icon: faFireBurner, description: "Barbecue" },
            { icon: faSink, description: "Docce idromassaggio" },
            { icon: faKitchenSet, description: "Cucina, frigo, forno, stoviglie" },
            { icon: faWifi, description: "Free WiFi" },
            { icon: faTree, description: "Giardino" },
            { icon: faParking, description: "Posto auto" },
            { icon: faUmbrellaBeach, description: "Spiaggia raggiungibile a piedi" },
            { icon: faTv, description: "Smart TV" },
            { icon: faBicycle, description: "Biciclette disponibili" }
        ]
    },
    {
        id: 5,
        title: "Attico",
        subtitle: "La miglior vista della villa",
        imagesUrl: [
            {
                id: 1,
                url: atticTerrace
            },
        ],
        description: "L'attico può ospitare fino a 6 persone. Situato al secondo piano, è composto da due camere da letto matrimoniali più un letto singolo, cucina, bagno con doccia idromassaggio per un tocco di benessere, terrazza panoramica di 150 mq arredata con gazebo, tavolo da 6 sedie, prendisole e barbecue a gas, lavatrice e pilozza da esterno per la massima comodità. L'appartamento è climatizzato e dotato di TV. Posto auto videosorvegliato.",
        services: [
            { icon: faDog, description: "Animali ammessi" },
            { icon: faWind, description: "Aria condizionata, riscaldamento" },
            { icon: faShower, description: "Doccia esterna" },
            { icon: faBroom, description: "Attrezzi pulizia" },
            { icon: faHandsBubbles, description: "Lavanderia" },
            { icon: faRestroom, description: "Bagno, lavatrice, ferro da stiro" },
            { icon: faFireBurner, description: "Barbecue" },
            { icon: faSink, description: "Docce idromassaggio" },
            { icon: faKitchenSet, description: "Cucina, frigo, forno, stoviglie" },
            { icon: faWifi, description: "Free WiFi" },
            { icon: faTree, description: "Giardino" },
            { icon: faParking, description: "Posto auto" },
            { icon: faUmbrellaBeach, description: "Spiaggia raggiungibile a piedi" },
            { icon: faTv, description: "Smart TV" },
            { icon: faBicycle, description: "Biciclette disponibili" }
        ]
    },
];