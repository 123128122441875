import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../images/logo/villa-anastasia-logo.svg';

interface LogoComponentProps {
  width: string;
  // Added: Optional URL for homepage
  homePageUrl?: string; // Defaults to "/" if not provided
}

const LogoVillaComponent: React.FC<LogoComponentProps> = ({ width, homePageUrl = "/" }) => {
  const navigateToHomepage = () => {
    // Use appropriate navigation library (e.g., React Router v6)
    window.location.href = homePageUrl;
  };

  return (
    <div>
      <img onClick={navigateToHomepage} src={Logo} alt="Villa Anastasia Logo" width={width} role='button'/>
    </div>
  );
};

export default LogoVillaComponent;