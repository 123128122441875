import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Apartment } from '../Interface/Apartment';
import CarouselComponent from './CarouselComponent';
import { apartmentsData } from '../data/ApartmentsData';
import { apartment1CarouselItems } from '../data/Apartment1Data'
import { apartment2CarouselItems } from '../data/Apartment2Data'
import { apartment3CarouselItems } from '../data/Apartment3Data'
import { apartment4CarouselItems } from '../data/Apartment4Data'
import { atticCarouselItems } from '../data/AtticData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CarouselItem } from '../Interface/CarouselComponentProps';


const carouselDataMapping = {
    1: apartment1CarouselItems,
    2: apartment2CarouselItems,
    3: apartment3CarouselItems,
    4: apartment4CarouselItems,
    5: atticCarouselItems
};


const ServicesAndDescriptionComponent = (): ReactElement => {

    const { id } = useParams();
    const [apartment, setApartment] = useState<Apartment>();
    const [selectedCarouselItems, setSelectedCarouselItems] = useState<CarouselItem[]>([]);

    useEffect(() => {
        window.scrollTo(0, 90);
        if (!id) {
            return;
        }
        //  altrimenti puoi proseguire 
        const myId = parseInt(id);
        const targetApartment = apartmentsData.find(apartment => apartment.id === myId);
        setApartment(targetApartment); // Previene errori se l'array è vuoto

        const carouselItems = carouselDataMapping[myId as keyof typeof carouselDataMapping];
        setSelectedCarouselItems(carouselItems || []); // Se non ci sono dati validi, impostalo come array vuoto


    }, [apartment, id]);

    return (
        <>
            <h6 className='display-5' style={{marginLeft: '5%'}}> {apartment?.title}</h6>
            {selectedCarouselItems.length > 0 && <CarouselComponent carouselItems={selectedCarouselItems} />}
            <div className='container'>
                <div className='row mt-3 mb-4'>
                    <div className="fw-bold fs-3 mb-2 mt-2"> Servizi:</div>
                    {apartment?.services.map((service, index) => (
                        <div className="col-sm-6 col-md-6 col-lg-4" key={index}>
                            <p><FontAwesomeIcon icon={service.icon} /> {service.description}</p>
                        </div>
                    ))}
                    <div className='container flex-row'>
                        <div className="fw-bold fs-3"> Descrizione:</div>
                        {apartment?.description}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesAndDescriptionComponent