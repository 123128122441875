import React from 'react'

import '../Style/CarouselStyle.css'
import { CarouselComponentProps } from '../Interface/CarouselComponentProps'




const CarouselComponent = (props: CarouselComponentProps) => {
    return (
        <>
            <div id="carouselExampleCaptions" className="carousel slide w-100 mb-4" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {props.carouselItems.map((item, index) => (
                        <button key={index} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={index.toString()} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : "false"} aria-label={`Slide ${index + 1}`}></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    {props.carouselItems.map((item, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                            <img className="img-carousel d-block w-100" src={item.image} alt={`Slide ${index + 1}`} />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 className="font-weight-bold">{item.caption.title}</h5>
                                <p>{item.caption.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    );
};

export default CarouselComponent;